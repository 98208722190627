<template>
	<div class="about">
		<h1>This is an about page</h1>
		<b-alert show>Default Alert</b-alert>
		<level-view
			:levelData="lvl.leveldata"
			:tileWidth="12"
			:levelName="lvl.name"
			v-for="lvl in randomLevels"
			:key="lvl.id"
		></level-view>
	</div>
</template>

<style lang="scss"></style>
<script>
// @ is an alias to /src
// import LevelView from "@/components/LevelView.vue";

// import Game from "./../../public/robbo/robbo"

import LevelView from '../components/LevelView.vue';

export default {
	name: 'Robbo',
	mounted: function() {
		fetch('/api/v1/levels/random/10')
			.then(data => data.json())
			.then(data => {
				this.randomLevels = data.lvlData;
			});
	},
	components: {
		LevelView
	},
	data: function() {
		return {
			randomLevels: []
			// testLevel: '3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,4,4,4,4,4,4,4,4,4,4,4,4,4,4,3,3,4,0,0,0,0,0,0,0,0,0,0,1,3,4,3,3,4,3,3,3,3,3,3,3,3,3,3,3,3,4,3,3,4,3,0,4,0,4,0,4,0,4,0,0,0,4,3,3,4,3,0,6,0,0,0,0,0,6,0,39,29,12,3,3,4,3,4,0,4,0,4,0,4,0,4,0,0,4,3,3,4,3,3,3,3,3,3,3,3,3,3,3,3,4,3,3,4,0,0,4,0,4,0,4,0,4,0,4,3,4,3,3,12,28,40,0,0,6,0,0,0,6,0,0,3,4,3,3,4,0,0,0,4,0,4,0,4,0,4,0,3,4,3,3,4,3,3,3,3,3,3,3,3,3,3,3,3,4,3,3,4,3,4,0,4,0,4,0,4,0,4,0,0,4,3,3,4,3,0,0,0,0,0,6,0,0,0,39,29,12,3,3,4,3,0,4,0,4,0,4,0,4,0,0,0,4,3,3,4,3,3,3,3,3,3,3,3,3,3,3,3,4,3,3,4,0,0,0,4,0,4,0,4,0,4,0,3,4,3,3,12,28,40,0,0,0,0,6,0,0,0,0,3,4,3,3,4,0,0,4,0,4,0,4,0,4,0,4,3,4,3,3,4,3,3,3,3,3,3,3,3,3,3,3,3,4,3,3,4,3,0,4,0,4,0,4,0,4,0,0,0,4,3,3,4,3,0,0,6,0,0,0,6,0,0,39,29,12,3,3,4,3,4,0,4,0,4,0,4,0,4,0,0,4,3,3,4,3,3,3,3,3,3,3,3,3,3,3,3,4,3,3,4,0,0,4,0,4,0,4,0,4,0,4,3,4,3,3,12,28,40,0,6,0,0,0,0,0,6,0,3,4,3,3,4,0,0,0,4,0,4,0,4,0,4,0,3,4,3,3,4,3,3,3,3,3,3,3,3,3,3,3,3,4,3,3,4,3,5,0,0,0,0,0,0,0,0,0,0,4,3,3,4,4,4,4,4,4,4,4,4,4,4,4,4,4,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3'
		};
	},
	computed: {},
	methods: {}
};
</script>
